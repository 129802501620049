import React, { useState, useContext } from 'react';
import { Col, Row, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { cleanCache } from 'services/cache';
import DeleteBotModal from 'pages/common/modals/deleteBot';
import ChangeBotStatusModal from './modals/ChangeBotStatus';
import SuccessfullyDeleteBotModal from 'pages/common/modals/successfullyDeleteBot';
import { BotState as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { firstProTier } from 'staticData/common';
import AddLiquidityModal from 'pages/common/modals/AddLiquidity';
import { useHistory } from 'react-router-dom';
import 'assets/scss/custom/botState.scss';

const BotState = ({ bot, setBot, setAddedLiquidity, parentLoaded }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const [deleteBotModalShow, setDeleteBotModalShow] = useState(false);
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  const [successfullyDeleteBotModalShow, setSuccessfullyDeleteBotModalShow] =
    useState(false);
  const [addLiquidityModalShow, setAddLiquidityModalShow] = useState(false);
  const routerHistory = useHistory();
  const [waitDelete, setWaitDelete] = useState(false);

  const handleSetStatus = async (newStatus, covertAll = false) => {
    setWaitDelete(true);
    let setStatusResponse = await endpointInterfaceV2({
      internalEndpoint: 'setBotStatus',
      httpMethod: 'post',
      usingCredentials: true,
      pathParams: {
        bot_id: bot.id
      },
      bodyParams: {
        status: newStatus,
        sell_all: newStatus === 'ACTIVE' ? false : covertAll
      }
    });
    setWaitDelete(false);
    if (setStatusResponse.validResponse) {
      await cleanCache(0);
      setBot({ ...bot, status: newStatus });
      if (newStatus === 'TERMINATED') {
        setDeleteBotModalShow(false);
        setSuccessfullyDeleteBotModalShow(true);
      }
    }
  };

  const tooltipMap = {
    ACTIVE: textLang.activeTooltip[lang],
    PAUSED: textLang.pausedTooltip[lang],
    ERROR: textLang.pausedTooltip[lang],
    TERMINATED: textLang.terminatedTooltip[lang]
  };

  const confirmBotDeletion = covertAll => {
    handleSetStatus('TERMINATED', covertAll);
  };

  const closeSuccessfullyDeleteBotModal = () => {
    setDeleteBotModalShow(false);
    setSuccessfullyDeleteBotModalShow(false);
    routerHistory.push('/dashboard/');
  };

  const handleAddButtonClick = () => {
    setAddLiquidityModalShow(true);
  };

  if (!parentLoaded)
    return (
      <Row className="justify-content-center g-0">
        <Row className="my-0">
          <Col className="text-center px-2">
            <div style={{ paddingTop: '10%', paddingBottom: '10%' }}>
              <Spinner />
            </div>
          </Col>
        </Row>
      </Row>
    );

  return (
    <>
      <DeleteBotModal
        deleteBotModalShow={deleteBotModalShow}
        setDeleteBotModalShow={setDeleteBotModalShow}
        confirmBotDeletion={confirmBotDeletion}
        bot={bot}
        waitDelete={waitDelete}
      />

      <ChangeBotStatusModal
        modalShow={changeStatusModalShow}
        setModalShow={setChangeStatusModalShow}
        bot={bot}
        setStatus={handleSetStatus}
      />

      <AddLiquidityModal
        modalShow={addLiquidityModalShow}
        setModalShow={setAddLiquidityModalShow}
        setAddedLiquidity={setAddedLiquidity}
        botID={bot.id}
        bot={bot}
      />

      <SuccessfullyDeleteBotModal
        successfullyDeleteBotModalShow={successfullyDeleteBotModalShow}
        closeSuccessfullyDeleteBotModal={closeSuccessfullyDeleteBotModal}
      />
      <Row className="justify-content-center g-0 mb-sm-3 mb-md-0">
        <Row className="my-0">
          <Col xs={4} className="text-center px-2">
            <OverlayTrigger
              placement={'top'}
              overlay={<Tooltip>{textLang.noAddTooltip[lang]}</Tooltip>}
            >
              <span>
                <IconButton
                  variant={'info'}
                  className="rounded-lg"
                  icon={'plus-circle'}
                  onClick={() => handleAddButtonClick()}
                  iconClassName="fa-2x mx-2 my-3"
                  disabled={true}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px 0px',
                    padding: '10px',
                    width: '100%',
                    borderRadius: '15px'
                  }}
                ></IconButton>
              </span>
            </OverlayTrigger>
          </Col>
          <Col xs={4} className="text-center px-2">
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip>{tooltipMap[bot.status.toUpperCase()]}</Tooltip>
              }
            >
              <span>
                <IconButton
                  variant={
                    bot.status.toUpperCase() === 'ACTIVE'
                      ? 'warning'
                      : 'success'
                  }
                  className="rounded-lg"
                  icon={
                    bot.status.toUpperCase() === 'ACTIVE'
                      ? 'pause-circle'
                      : 'play-circle'
                  }
                  onClick={() =>
                    (bot.exchange === 'virtual' ||
                      userInfos?.pro_tier >= firstProTier) &&
                    bot.type !== 'takeProfitBot30'
                      ? setChangeStatusModalShow(true)
                      : null
                  }
                  iconClassName="fa-2x mx-2 my-3"
                  disabled={
                    bot.status.toUpperCase() === 'TERMINATED' ||
                    (bot.exchange !== 'virtual' &&
                      userInfos?.pro_tier < firstProTier) ||
                    bot.type === 'takeProfitBot30'
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px 0px',
                    padding: '10px',
                    width: '100%',
                    borderRadius: '15px'
                  }}
                ></IconButton>
              </span>
            </OverlayTrigger>
          </Col>
          <Col xs={4} className="text-center px-2">
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip>
                  {bot.status.toUpperCase() == 'TERMINATED'
                    ? textLang.noDelete[lang]
                    : textLang.delete[lang]}
                </Tooltip>
              }
            >
              <span>
                <IconButton
                  variant={'danger'}
                  className="rounded-md"
                  icon="trash"
                  iconClassName="fa-2x mx-2 my-3"
                  onClick={() => setDeleteBotModalShow(true)}
                  disabled={bot.status.toUpperCase() == 'TERMINATED'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px 0px',
                    padding: '10px',
                    width: '100%',
                    borderRadius: '15px'
                  }}
                ></IconButton>
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
      </Row>
    </>
  );
};

BotState.propTypes = {
  bot: PropTypes.object.isRequired,
  setBot: PropTypes.func.isRequired,
  setAddedLiquidity: PropTypes.func,
  dataReady: PropTypes.bool.isRequired,
  parentLoaded: PropTypes.bool
};

export default BotState;
